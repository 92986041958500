import React from "react"
import {Card, CardBody, CardTitle, Media} from "reactstrap"
import moment from 'moment'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { config } from "../../config";
// import 'moment/locale/it';
// moment.locale('it')

const AlertComp = (props) => {

    const {t} = useTranslation()

    const capitalizeFirstLetter = (string) => {

        const date = string.split(' ')[0];
        const month = string.split(' ')[1];

        return (date + ' ' + month.charAt(0).toUpperCase() + month.slice(1));
    }

    const RenderItems = () => {

        const Data = props.systemAlerts.map((item, id) => {
            return (
                <li key={id} className={item.read ? "event-list active notifi-item" : "event-list notifi-item"}>
                    <div className="event-timeline-dot">
                        <i className={item.read ? "bx bx-right-arrow-circle font-size-18" : "bxs-right-arrow-circle text-new-primary bx font-size-18"}/>
                    </div>
                    <Media>
                        <div className="me-3">
                            <h5 className="font-size-14 text-">
                                {`${capitalizeFirstLetter(moment.utc(item.time).local().format('DD MMM'))}`}
                                <br/>
                                {moment.utc(item.time).local().format('HH:mm')}
                                <i
                                    className={"bx bx-right-arrow-alt font-size-16 align-middle ms-2 text-new-" +
                                        (item.level === 'Info' ? 'primary' : item.level === 'Error' ? 'danger' : item.level.toLowerCase())
                                    }
                                />
                            </h5>
                        </div>
                        <Media body>
                            <div>{item.message?.config?.LANGUAGE ? item.message[item.message.config.LANGUAGE] : item.message[Object.keys(item.message)[0]]}</div>
                        </Media>
                    </Media>
                </li>
            )
        })

        return (Data);
    }

    return (
        <React.Fragment>
            <Card className='notifi-container-dashboard alert-card'>
                <CardBody className='mt-2 mb-1 notifi-container-dashboard'>
                    <CardTitle style={{textAlign: 'left'}} className="mb-4 d-flex justify-content-between">
                        <div>{t("dashboard.notifications.topic")}</div>
                        <Link to={`/notifications`} style={{textDecoration:'none'}}>
                            <div className='fw-normal d-flex font-size-13'>
                                <div className='text-new-primary' style={{color: config.getColors().primary}}>{t("dashboard.notifications.view")}</div>
                                <i className='ms-1 bx bx-right-arrow-alt bx-xs text-new-primary' style={{color: config.getColors().primary}}/>
                            </div>
                        </Link>
                    </CardTitle>
                    <div className='alert-list pt-3 pb-2'>
                        <ul className="verti-timeline list-unstyled">

                            {props.systemAlerts && props.systemAlerts.length ?
                                <RenderItems/>
                                :
                                <div className='font-size-14 text-center' style={window.innerWidth >= 768 ? {
                                    marginTop: 70, 
                                } : {marginTop: '1.5rem', marginBottom: '1.5rem'}}>{t("dashboard.notifications.null")}</div>
                            }

                        </ul>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default AlertComp;
