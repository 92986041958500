import {CardBody, Card} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation, useParams} from "react-router-dom";
import moment from "moment";

import Breadcrumb from "../../components/common/Breadcrumb";
import OrchardRegisterForm from "../../components/forms/OrchardRegisterForm";
import Layout from "../../layout/layout";

import {useDispatch, useSelector} from "react-redux";
import {getAvailablePlants,} from "../../redux/actions/systemPlantActions";
import {
    addRegisterEntry,
    getRegisterEntryData, getRegisterEntriesCategoriesList,
    updateRegisterEntry, getInterventionTypesList, getEntryImagesData
} from "../../redux/actions/orchardRegisterActions";
import {getAvailableSystemAreas} from "../../redux/actions/systemAreaActions";
import {config} from "../../config";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

function AddEditViewRegisterEntry({isEdit, isDisabled}) {

    const {t} = useTranslation()
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const plantParam = searchParams.get("from-plants")

    const available = useSelector((el) => el.systemData.availableList)
    const registerEntryData = useSelector((el)=> el.orchardRegisterData.registerEntryData)
    const registerEntryImageData = useSelector((el)=> el.orchardRegisterData.registerEntryImageData)
    const errorData = useSelector((el) => el.loadingData.error)
    const systemareas = useSelector((el) => el.systemAreaData.availableSystemAreas)
    const plants = useSelector((el) => el.systemPlantData.availablePlants)
    const registerEntryCategories = useSelector((el)=> el.orchardRegisterData.registerEntryCategoriesList)
    const interventionTypes = useSelector((el)=> el.orchardRegisterData.interventionTypeList)

    const [formState, setFormState] = useState({
        title: "",
        areas: [],
        plants: [],
        date: moment().format('YYYY-MM-DDTHH:mm'),
        notes: "",
        vegetative_phase: "",
        pruning_type: "",
        operator: "",
        intervention_reason: "",
        product_type: "",
        label_dose: "",
        effective_dose: "",
        m2_dosage: "",
        intervention_mode_type: "",
        extension_ha: "",
        adversity: "",
        product_commercial_name: "",
        quantity: "",
        used_solution: "",
        entry_category: ""
    })

    const [fileState, setFileState] = useState([])

    useEffect(() => {

        if(isEdit || isDisabled){
            if(!available.some((el) => el._id === params.systemId)){
                history.push(`/systems`)
            } else {
                dispatch(getRegisterEntriesCategoriesList(params.systemId))
                dispatch(getInterventionTypesList())
                dispatch(getRegisterEntryData(params.entryId, params.systemId))
                dispatch(getEntryImagesData(params.entryId, params.systemId))
            }
        }

        if (!isDisabled){
            dispatch(getRegisterEntriesCategoriesList(params.systemId))
            dispatch(getInterventionTypesList())
            dispatch(getAvailableSystemAreas(params.systemId))
            dispatch(getAvailablePlants(params.systemId))
        }
    }, [])

    useEffect(() => {
        if (registerEntryData && registerEntryData?.entry && (isEdit || isDisabled)) {
            setFormState({
                ...formState,
                ...registerEntryData.entry,
                _id: registerEntryData.entry._id,
                title: registerEntryData.entry.title,
                plants: registerEntryData.entry.plants,
                areas: registerEntryData.entry.systemareas,
                date: moment(registerEntryData.entry.date).format('YYYY-MM-DDTHH:mm'),
                notes: registerEntryData.entry.notes,
            })
        }
    }, [registerEntryData])

    useEffect(() => {
        if(registerEntryImageData && registerEntryImageData?.length >= 0 && (isEdit || isDisabled)) {
            let imgArr = [];
            for(let i=0; i<registerEntryImageData?.length; i++) {
                const id = registerEntryImageData[i]?._id;
                const fileObj = {
                    _id: id,
                    url: `${baseURL}plantsystem/ui/registerentries/images/${id}?system=${params.systemId}&size=small`
                }
                imgArr.push(fileObj);
            }
            setFileState(imgArr);
        }
    }, [JSON.stringify(registerEntryImageData)]);

    useEffect(() => {
        if (errorData && errorData.code === 404 ) {
            if(params.categoryId === 'general') {
                history.push(`/system/${params.systemId}/register-entries/`)
            } else {
                history.push(`/system/${params.systemId}/register-entries/category/${params.categoryId}`)
            }
        }
    }, [errorData])

    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data.title = formState.title;
        data.plants = formState.plants.map((el)=> {return el._id});
        data.systemareas = formState.areas.map((el)=> {return el._id});
        data.date = formState.date;
        data.notes = formState.notes;
        data.operator = formState.operator;

        if(formState._id){
            data._id = formState._id;
            dispatch(updateRegisterEntry(data, formState, params.systemId, params.categoryId, fileState)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/register-entries/category/${params.categoryId}`)
                }
            })
        } else {
            dispatch(addRegisterEntry(data, formState, params.systemId, params.categoryId === 'general' ? formState?.entry_category : params.categoryId, fileState)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/register-entries/category/${params.categoryId === 'general' ? formState?.entry_category : params.categoryId}`)
                }
            })
        }
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('registerEntry.editRegisterEntry') : isDisabled ? formState.title : t('registerEntry.addRegisterEntry')}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/register-entries`, name: t('registerEntry.breadcrumb1')},
                        {
                            path:
                                isEdit ? `system/${params.systemId}/register-entries/category/${params.categoryId}/entry/edit/${params.entryId}`
                                    :
                                isDisabled ?  `system/${params.systemId}/register-entries/category/${params.categoryId}/entry/view/${params.entryId}`
                                    :
                                `system/${params.systemId}/register-entries/category/${params.categoryId}/entry/add`,

                            name: t(isEdit ? 'registerEntry.breadcrumbEdit' : isDisabled ? formState.title : 'registerEntry.breadcrumbAdd')
                        }
                    ]}
                />
                <Card>
                    <CardBody>
                        <OrchardRegisterForm
                            systemareas={systemareas}
                            plants={plants}
                            interventionTypes={interventionTypes}
                            registerEntryCategories={registerEntryCategories}
                            categoryName={registerEntryCategories.find((el) => el._id === params.categoryId)?.label[config.LANGUAGE] ?? registerEntryCategories.find((el) => el._id === params.categoryId)?.label.it}
                            onSaveClick={onSaveClick}
                            formState={formState}
                            setFormState={setFormState}
                            isDisabled={isDisabled}
                            fileState={fileState}
                            setFileState={setFileState}
                            plantParam={plantParam}
                            categoryId={params.categoryId === 'general' ? formState?.entry_category : params.categoryId}
                            systemId={params.systemId}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    );
}

export default AddEditViewRegisterEntry;
