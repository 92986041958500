import {CardBody, CardTitle, Card, Input, Col, Row} from "reactstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Switch from "react-switch";
import {dataConsumptionData} from "../../../utils/appData";
import { config } from "../../../config";

const DataConsumption=()=>{

    const {t} = useTranslation();

    const [switchCardState, setSwitchCardState] = useState({
        isSwitchOpen: false,
        value: 1
    })

    const onChangeHandlerSwitch=(value)=>{
        setSwitchCardState({
            ...switchCardState,
            isSwitchOpen: value
        })
    }

    const onChangeHandler=(e)=>{
        setSwitchCardState({
            ...switchCardState,
            value: Number(e.target.value)
        })
    }

    return(
    <Card>
        <CardBody>
            <CardTitle style={{textAlign: 'left'}}>{t("dashboard.dataConsumption.topic")}</CardTitle>
            <div className='mt-5 mt-md-4 d-block d-md-flex align-items-md-center'>
                <div className='d-flex flex-row align-items-center mt-2 me-5'>
                    <div style={{fontWeight:'500'}} className='text-nowrap me-4 font-size-14'>{t("dashboard.dataConsumption.form.field")}</div>
                    <Switch
                        onChange={onChangeHandlerSwitch}
                        checked={switchCardState.isSwitchOpen}
                        checkedIcon={<span style={{color:'#fff', marginLeft:5, lineHeight:'27px'}}>On</span>}
                        uncheckedIcon={false}
                        onHandleColor="#fff"
                        offHandleColor='#ccc'
                        onColor={config.getColors().blue}
                        offColor='#fff'
                        handleDiameter={20}
                        boxShadow="0px rgba(0, 0, 0, 0)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0)"
                    />
                </div>
                <div style={{width:"100%"}} className='mt-4 mt-md-0'>
                    <Col className='text-start mt-2'>
                        <Input
                            type='select'
                            name='switch_dropdown'
                            id='switch_dropdown'
                            value={switchCardState.value}
                            className='form-select form-control'
                            onChange={onChangeHandler}
                            disabled={switchCardState.isSwitchOpen}
                        >
                            {dataConsumptionData.map((item)=>{
                                return(
                                    <option key={"data-consumption-value-" +item.value} value={item.value}>{t(item.name)}</option>
                                )
                            })}
                        </Input>
                    </Col>
                </div>
            </div>
        </CardBody>
    </Card>
    )
}

export default DataConsumption;