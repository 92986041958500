import {useTranslation} from "react-i18next";
import {Card} from "reactstrap";

import Layout from "../layout/layout";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

function ErrorPage() {

    const {t} = useTranslation();
    const history = useHistory();

    const available = useSelector((el) => el.systemData.availableList)

    useEffect(() => {
        if(available?.length) {
            history.push(`/system/${available[0]._id}/dashboard`)
        }
    }, [available?.length]);

    return (
        <Layout>
            <Card className="p-5 mt-5 text-center">
                {t("main.noGarden")}
            </Card>
        </Layout>
    );
}

export default ErrorPage;
