import React, { useState } from 'react';
import { ModalHeader, Modal, ModalBody, Button } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import AltTimeRangeSelect from '../others/AltTimeRangeSelect';
import { config } from '../../config';

let en = require('apexcharts/dist/locales/en.json');
let it = require('apexcharts/dist/locales/it.json');

const DataExportModal = (props) => {
  const { t } = useTranslation();

  const toggleModal = () => {
    props.setIsOpen(!props.isOpen);
  };

  const [checkedValue, setCheckedValue] = useState('lastyear');
  const [dates, setDates] = useState({
    fromDate: '',
    toDate: '',
  });

  const [filterData, setFilterData] = useState({
    value: 'lastyear',
    from: null,
    to: null,
  });

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
      locales: [en, it],
      defaultLocale: 'en',
    },
    stroke: { curve: 'smooth', width: 2 },
    xaxis: {
      type: 'datetime',
      min: moment().subtract(1, 'years').valueOf(),
      max: moment().valueOf(),
      tickAmount: 6,
      labels: {
        format: 'dd MMM',
        datetimeUTC: false,
      },
    },
    colors: [config.getColors().blue],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    tooltip: {
      x: { format: 'dd MMM' },
    },
  });

  // const onApplyFilters = (value, dates) => {
  //   let min;
  //   let max;
  //   let format;

  //   if (value === 'today') {
  //     min = moment().subtract(1, 'days').valueOf();
  //     format = 'MMM dd, HH:mm';
  //   } else if (value === 'lastweek') {
  //     min = moment().subtract(1, 'weeks').valueOf();
  //     format = 'MMM dd, HH:mm';
  //   } else if (value === 'lastmonth') {
  //     min = moment().subtract(1, 'months').valueOf();
  //     format = 'MMM dd';
  //   } else if (value === 'lastsixmonth') {
  //     min = moment().subtract(6, 'months').valueOf();
  //     format = 'MMM dd';
  //   } else if (value === 'lastyear') {
  //     min = moment().subtract(1, 'years').valueOf();
  //     format = 'yyyy MMM';
  //   } else {
  //     min = moment(dates.fromDate).valueOf();
  //     max = moment(dates.toDate).valueOf();
  //     format = 'yyyy MMM dd, HH:mm';
  //   }
  //   props.getAllDevicesData(value, dates.fromDate, dates.toDate);

  //   setFilterData({
  //     value: value,
  //     from: dates.fromDate,
  //     to: dates.toDate,
  //   });
  //   setOptions({
  //     ...options,
  //     xaxis: {
  //       ...options.xaxis,
  //       min: min,
  //       max: max ? max : moment().valueOf(),
  //       labels: {
  //         format: format,
  //       },
  //     },
  //     tooltip: {
  //       x: { format: format },
  //     },
  //   });
  // };

  const onExportData = (value, from, to) => {
    props.onExportAll(value, from, to);
  };
  const handleClick = () => {
    onExportData(checkedValue, dates.fromDate, dates.toDate);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      autoFocus={true}
      tabIndex="-1"
      data-toggle="modal"
      size="xl"
    >
      <div className="modal-content">
        <ModalHeader toggle={toggleModal}>{props.title}</ModalHeader>

        <ModalBody>
          <div className="mt-5 mb-5">
            <AltTimeRangeSelect
              checkedValue={checkedValue}
              setCheckedValue={setCheckedValue}
              dates={dates}
              setDates={setDates}
            />
          </div>

          {!props.hideExport && (
            <div className="mt-2 mb-2">
              <Button onClick={handleClick} color="primary">
                {t('main.export')}
              </Button>
            </div>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default DataExportModal;
