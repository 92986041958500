import React, { useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import moment from 'moment'
import {useTranslation} from "react-i18next";
import { config } from "../../../config";

var it = require("apexcharts/dist/locales/it.json")

const CompareGraph = props => {

  const {t} = useTranslation();

  const data = [{
    name: t("dashboard.compareGraph.current"),
    data: props.compareGraphData ? props.compareGraphData.current : []
  },{
    name: t('dashboard.compareGraph.previous'),
    data: props.compareGraphData ? props.compareGraphData.previous : []
  }]

  const [activeState, setActiveState] = useState({
    activeM: false,
    active6M: false,
    activeY: true,
  });

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
      locales: [it],
      defaultLocale: 'it',
    },
    stroke: { curve: "smooth", width: 2 },
    xaxis: {
      type: "datetime",
      min:  moment().subtract(1, 'months').valueOf(),
      max: moment().valueOf(),      
      tickAmount: 6,
      labels: {
        format: 'dd MMM',
        datetimeUTC: false
      }
    },
    colors: [config.getColors().blue, config.getColors().yellow],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    tooltip:{
      x:{ format: 'dd MMM' }
    }
  })


  const updateChartMonthly=()=>{
    props.getData('lastmonth')

    setOptions({
      ...options,
      xaxis:{
        ...options.xaxis,
        min: moment().subtract(1, 'months').valueOf(),
        labels:{
          format:'dd MMM',
          datetimeUTC: false
        }
      },
      tooltip:{
        x: { format:'dd MMM' }
      }
    })
    setActiveState({
      ...activeState,
        activeM: true,
    })
    props.setGraphState('1M');
  }

  const updateChartSixMonth=()=>{
    props.getData('lastsixmonth')

    setOptions({
      ...options,
      xaxis:{
        ...options.xaxis,
        min: moment().subtract(6, 'months').valueOf(),
        labels:{
          format:'MMM',
          datetimeUTC: false
        }
      },
      tooltip:{
        x: { format:'MMM' }
      }
    })
    setActiveState({
      ...activeState,
        active6M: true,
    })
    props.setGraphState('6M');
  }

  const updateChartYearly=()=>{
    props.getData('lastyear')

    setOptions({
      ...options,
      xaxis:{
        ...options.xaxis,
        min: moment().subtract(1, 'years').valueOf(),
        labels:{
          format:'MMM',
          datetimeUTC: false
        }
      },
      tooltip:{
        x: { format:'MMM' }
      }
    })
    setActiveState({
      ...activeState,
        activeY: true,
    })
    props.setGraphState('1Y');
  }

  return (
    <React.Fragment>
      <Col>        
        <Card className='compare-card'>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">{t("dashboard.compareGraph.topic")}</h5>
              <div className="ms-auto">
                <div className="toolbar button-items text-end">
                  <button onClick={updateChartMonthly} type="button" className={"btn btn-light btn-sm " + (props.graphState === '1M' ? 'btn-selected' : '')}>
                    1M
                  </button>{" "}
                  <button onClick={updateChartSixMonth} type="button" className={"btn btn-light btn-sm " + (props.graphState === '6M' ? 'btn-selected' : '')}>
                    6M
                  </button>{" "}
                  <button onClick={updateChartYearly} type="button" className={"btn btn-light btn-sm " + (props.graphState === '1Y' ? 'btn-selected' : '')}>
                    1Y
                  </button>{" "}
                </div>
              </div>
            </div>

            <Row className="text-center" style={{marginTop:4}}>
              <Col md={4}>
                <div className="mt-2">
                  <p className="text-muted mb-1">{t("dashboard.compareGraph.maximum")}</p>
                  <h5>{props.graphInfo.max ? props.graphInfo.max : 0}</h5>
                </div>
              </Col>

              <Col md={4}>
                <div className="mt-2">
                  <p className="text-muted mb-1">{t("dashboard.compareGraph.average")}</p>
                  <h5>
                    {props.graphInfo.avg ? props.graphInfo.avg : 0}
                    <span className="text-success font-size-13">
                    </span>
                  </h5>
                </div>
              </Col>

              <Col md={4}>
                <div className="mt-2">
                  <p className="text-muted mb-1">{t("dashboard.compareGraph.minimum")}</p>
                  <h5>
                    {props.graphInfo.min ? props.graphInfo.min : 0}
                    <span className="text-success font-size-13">
                    </span>
                  </h5>
                </div>
              </Col>
            </Row>

            <hr className="mb-5" />
            <div id="overview-chart-timeline">
              <ReactApexChart
                options={options}
                series={data}
                type="area"
                height={240}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default CompareGraph;
