import {orchardRegisterActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getRegisterEntriesCategoriesList = (systemId) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_REGISTER_ENTRIES_CATEGORIES_LIST_REQUEST})

    try {
        const res = await request({
          url: `plantsystem/ui/registercategories?system=${systemId}`,
          auth: true,
          method: "GET",
        });
        dispatch({
            type: orchardRegisterActionTypes.GET_REGISTER_ENTRIES_CATEGORIES_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_REGISTER_ENTRIES_CATEGORIES_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getInterventionTypesList = () => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_INTERVENTION_TYPES_LIST_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/pottedPlants/interventionTypes`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: orchardRegisterActionTypes.GET_INTERVENTION_TYPES_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_INTERVENTION_TYPES_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getRegisterEntriesList = (id, type, page, searchText) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_REGISTER_ENTRIES_LIST_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries?system=${id}&entry_category_type=${type}&limit=5&page=${page}${searchText ? "&searchText=" + searchText : ""}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: orchardRegisterActionTypes.GET_REGISTER_ENTRIES_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_REGISTER_ENTRIES_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getPlantRegisterEntriesList = (id, plantId, page) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_PLANT_REGISTER_ENTRIES_LIST_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries?system=${id}&plant=${plantId}&limit=5&page=${page}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: orchardRegisterActionTypes.GET_PLANT_REGISTER_ENTRIES_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_PLANT_REGISTER_ENTRIES_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getRegisterEntryData = (id, systemId) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_REGISTER_ENTRY_DATA_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/${id}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: orchardRegisterActionTypes.GET_REGISTER_ENTRY_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_REGISTER_ENTRY_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addRegisterEntry = (Data, extraData, id, categoryId, fileState) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.CREATE_REGISTER_ENTRY_REQUEST});

    let data = new URLSearchParams();

    data.append('title', Data.title);
    data.append('date', Data.date);
    data.append('notes', Data.notes);
    data.append('operator', Data.operator);
    data.append('entry_category_type', categoryId);

    for(let i = 0; i<Data.plants?.length; i++){
        data.append(`plants[${i}]`, Data.plants[i]);
    }

    for(let i = 0; i<Data.systemareas?.length; i++){
        data.append(`systemareas[${i}]`, Data.systemareas[i]);
    }

    if(categoryId === "pruning") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        data.append('pruning_type', extraData.pruning_type);
    } else if(categoryId === "fertilization_biostimulation_interventions_um") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        data.append('intervention_reason', extraData.intervention_reason);
        data.append('product_type', extraData.product_type);
        if(extraData.label_dose) data.append('label_dose', extraData.label_dose);
        if(extraData.effective_dose) data.append('effective_dose', extraData.effective_dose);
    } else if(categoryId === "release_beneficial_insects") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        data.append('intervention_reason', extraData.intervention_reason);
        data.append('product_type', extraData.product_type);
        if(extraData.m2_dosage) data.append('m2_dosage', extraData.m2_dosage);
    } else if(categoryId === "potted_plants") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        if(extraData.intervention_mode_type) data.append('intervention_mode_type', extraData.intervention_mode_type);
    } else if(categoryId === "phytosanitary_treatment_log") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        data.append('intervention_reason', extraData.intervention_reason);
        if(extraData.extension_ha) data.append('extension_ha', extraData.extension_ha);
        data.append('adversity', extraData.adversity);
        data.append('product_commercial_name', extraData.product_commercial_name);
        if(extraData.quantity) data.append('quantity', extraData.quantity);
        if(extraData.used_solution) data.append('used_solution', extraData.used_solution);
    }

    let categoryUrl;

    if (categoryId === "pruning") categoryUrl = "pruning";
    else if (categoryId === "lawn_mowing") categoryUrl = "mowing";
    else if (categoryId === "fertilization_biostimulation_interventions_um") categoryUrl = "interventions";
    else if (categoryId === "release_beneficial_insects") categoryUrl = "insects";
    else if (categoryId === "potted_plants") categoryUrl = "pottedPlants";
    else if (categoryId === "general_notes_inspections") categoryUrl = "inspections";
    else if (categoryId === "phytosanitary_treatment_log") categoryUrl = "phytosanitary";

    let imgData;
    if(fileState?.length) {
        imgData = new FormData();

        for(let i=0; i<fileState.length; i++) {
            imgData.append("images", fileState[i]?.file);
            imgData.append(`order[${i}]`, fileState[i]?.file?.name);
        }
    }


    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/${categoryUrl}?system=${id}`,
            auth: true,
            method: 'POST',
            data: data
        });

        if(fileState?.length) {
             await request({
                url: `plantsystem/ui/registerentries/images?system=${id}&resource_id=${res._id}`,
                auth: true,
                method: 'PATCH',
                data: imgData
            });
        }

        dispatch({
            type: orchardRegisterActionTypes.CREATE_REGISTER_ENTRY_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.CREATE_REGISTER_ENTRY_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updateRegisterEntry = (Data, extraData, systemId, categoryId, fileState) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.UPDATE_REGISTER_ENTRY_REQUEST});

    let data = new URLSearchParams();

    data.append('title', Data.title);
    data.append('date', Data.date);
    data.append('notes', Data.notes);
    data.append('operator', Data.operator);

    for(let i = 0; i<Data.plants?.length; i++){
        data.append(`plants[${i}]`, Data.plants[i]);
    }

    for(let i = 0; i<Data.systemareas?.length; i++){
        data.append(`systemareas[${i}]`, Data.systemareas[i]);
    }

    if(categoryId === "pruning") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        data.append('pruning_type', extraData.pruning_type);
    } else if(categoryId === "fertilization_biostimulation_interventions_um") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        data.append('intervention_reason', extraData.intervention_reason);
        data.append('product_type', extraData.product_type);
        if(extraData.label_dose) data.append('label_dose', extraData.label_dose);
        if(extraData.effective_dose) data.append('effective_dose', extraData.effective_dose);
    } else if(categoryId === "release_beneficial_insects") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        data.append('intervention_reason', extraData.intervention_reason);
        data.append('product_type', extraData.product_type);
        if(extraData.m2_dosage) data.append('m2_dosage', extraData.m2_dosage);
    } else if(categoryId === "potted_plants") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        if(extraData.intervention_mode_type) data.append('intervention_mode_type', extraData.intervention_mode_type);
    } else if(categoryId === "phytosanitary_treatment_log") {
        data.append('vegetative_phase', extraData.vegetative_phase);
        data.append('intervention_reason', extraData.intervention_reason);
        if(extraData.extension_ha) data.append('extension_ha', extraData.extension_ha);
        data.append('adversity', extraData.adversity);
        data.append('product_commercial_name', extraData.product_commercial_name);
        if(extraData.quantity) data.append('quantity', extraData.quantity);
        if(extraData.used_solution) data.append('used_solution', extraData.used_solution);
    }


    let categoryUrl;

    if (categoryId === "pruning") categoryUrl = "pruning";
    else if (categoryId === "lawn_mowing") categoryUrl = "mowing";
    else if (categoryId === "fertilization_biostimulation_interventions_um") categoryUrl = "interventions";
    else if (categoryId === "release_beneficial_insects") categoryUrl = "insects";
    else if (categoryId === "potted_plants") categoryUrl = "pottedPlants";
    else if (categoryId === "general_notes_inspections") categoryUrl = "inspections";
    else if (categoryId === "phytosanitary_treatment_log") categoryUrl = "phytosanitary";

    let imgData = new FormData();

    if(fileState?.length) {
        for(let i=0; i<fileState.length; i++) {
            if(fileState[i]?.file) {
                imgData.append("images", fileState[i]?.file);
            }
            imgData.append(`order[${i}]`, fileState[i]._id ? fileState[i]._id : fileState[i].file.name);
        }
    } else {
        imgData.append("order[]", "");
    }

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/${categoryUrl}/${Data._id}?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        await request({
            url: `plantsystem/ui/registerentries/images?system=${systemId}&resource_id=${Data._id}`,
            auth: true,
            method: 'PATCH',
            data: imgData
        });

        dispatch({
            type: orchardRegisterActionTypes.UPDATE_REGISTER_ENTRY_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.UPDATE_REGISTER_ENTRY_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteRegisterEntry = (id, systemId) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.DELETE_REGISTER_ENTRY_REQUEST});

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/${id}?system=${systemId}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: orchardRegisterActionTypes.DELETE_REGISTER_ENTRY_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.DELETE_REGISTER_ENTRY_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getEntryImagesData = (id, systemId) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_REGISTER_ENTRY_IMAGE_DATA_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/images/?system=${systemId}&resource_id=${id}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: orchardRegisterActionTypes.GET_REGISTER_ENTRY_IMAGE_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_REGISTER_ENTRY_IMAGE_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}