// export const getTargetCode=()=>{
//     if (process.env.REACT_APP_TARGET_PROJECT === 'PLT'){
//         return 'PLT'
//     } else if (process.env.REACT_APP_TARGET_PROJECT === 'VLM'){
//             return 'VLM'
//     } else {
//         return 'PLT'
//     }
// }

const getTargetCode = (targetProject, targetEnv) => {
  if (targetProject === "PLT") {
    return "PLT";
  } else if (targetProject === "VLM" && targetEnv === "LOCAL") {
    return "VLM_LOCAL";
  } else {
    return "VLM";
  }
};

const getTextNs = (targetProject) => {
  return targetProject.toLowerCase();
};

const TARGET_PROJECT = process?.env.REACT_APP_TARGET_PROJECT || "PLT";

const TARGET_ENV = process?.env.REACT_APP_TARGET_ENV || "CLOUD";

const API_ENDPOINT =
  process?.env.REACT_APP_API_ENDPOINT || "http://localhost:3001";

const TARGET_CODE = getTargetCode(TARGET_PROJECT, TARGET_ENV);

const TEXT_NAMESPACE = getTextNs(TARGET_PROJECT);

const LANGUAGE = process?.env.REACT_APP_LANGUAGE || "en";

const GOOGLE_API_KEY = process?.env.REACT_APP_GOOGLE_API_KEY || "";

const ANDROID_APP_ID =
  process?.env.REACT_APP_ANDROID_ID ||
  "https://play.google.com/store/apps/details?id=it.lifetronic.plantCare5";

const IOS_APP_ID =
  process?.env.REACT_APP_IOS_ID ||
  " https://testflight.apple.com/join/XqzEzSzV";

const getColors = () => ({
  primary: getComputedStyle(document.documentElement)
    .getPropertyValue("--bs-primary")
    .trim(),
  blue: getComputedStyle(document.documentElement)
    .getPropertyValue("--bs-blue")
    .trim(),
  green: getComputedStyle(document.documentElement)
    .getPropertyValue("--bs-green")
    .trim(),
  yellow: getComputedStyle(document.documentElement)
    .getPropertyValue("--bs-yellow")
    .trim(),
  red: getComputedStyle(document.documentElement)
    .getPropertyValue("--bs-red")
    .trim(),
  black: getComputedStyle(document.documentElement)
    .getPropertyValue("--bs-black")
    .trim(),
});

export const config = {
  TARGET_PROJECT,
  TARGET_ENV,
  API_ENDPOINT,
  TARGET_CODE,
  TEXT_NAMESPACE,
  LANGUAGE,
  GOOGLE_API_KEY,
  ANDROID_APP_ID,
  IOS_APP_ID,

  getColors,
};
