import React from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ReactComponent as LoginLogo } from "../assets/icons/login-logo.svg";
import { config } from "../config";
import { verifyEmail } from "../redux/actions/authActions";

const EmailVerification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const TARGET_CODE = config.TARGET_CODE;
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);

  const handleVerifyEmail = (e) => {
    e.preventDefault();

    const token = searchParams.get("token");
    if (token) {
      dispatch(verifyEmail({ token })).then((data) => {
        if (data) {
          const userAgent =
            navigator.userAgent || navigator.vendor || window.opera;
          if (/android/i.test(userAgent)) {
            setTimeout(() => {
              window.location.href = config.ANDROID_APP_ID;
            }, 1000);
          } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setTimeout(() => {
              window.location.href = config.IOS_APP_ID;
            }, 1000);
          } else {
            history.push(`/login?type=verifyEmail`);
          }
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Container className="login-container">
        <Row
          style={{ minHeight: "100vh" }}
          className="align-items-center m-0 p-0"
        >
          <Col md={4} className="p-0 ps-2" style={{ zIndex: 1 }}>
            <Card className="overflow-hidden m-5">
              <div className="bg-primary bg-soft">
                <Row>
                  <div className="text-primary p-4 text-start">
                    <h5 className="text-primary">
                      {t("emailVerification.title")}
                    </h5>
                    <p>{t("emailVerification.subtitle")}</p>
                  </div>
                </Row>
              </div>

              <CardBody className="pt-0">
                <div className="avatar-md mb-4" style={{ marginTop: "-26px" }}>
                  <span className="avatar-title rounded-circle bg-light">
                    <LoginLogo className="avatar-title rounded-circle" />
                    {/* <span className="avatar-title rounded-circle bg-light">
                    <img src={logo} alt="Login logo" className="rounded-circle" height="34"/>
                  </span> */}
                  </span>
                </div>

                <div className="p-2 pt-1">
                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      onClick={handleVerifyEmail}
                    >
                      {t("emailVerification.verifyButton")}
                    </button>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Link to={`/login`} className="text-new-dark">
                    {t("emailVerification.return")}
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md={8} className="login-img">
            <div style={{ position: "relative" }}>
              <img
                style={{ width: "100%", objectFit: "cover", height: "100%" }}
                src={`/images/${
                  TARGET_CODE === "PLT" ? "plt" : "vlm"
                }/login_bg.png`}
                alt="Background"
              />
              {TARGET_CODE.includes("VLM") && (
                <div className="login-bg-2">
                  <img src="/images/vlm/login_bg2.png" alt="Background 2" />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default EmailVerification;
