import { systemActionTypes, authActionTypes } from "../actionTypes/index";
import produce from "immer";

const initialState = {
    availableList:[],
    availableLoaded:false,
    systemList: [],
    systemData: null,
    totalSize: 0
};

const systemData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case systemActionTypes.GET_AVAILABLE_SYSTEMS_SUCCEED: {
                state.availableList = payload;
                state.availableLoaded = true;
                return state;
            }
            case systemActionTypes.GET_SYSTEM_LIST_SUCCEED: {
                state.systemList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case systemActionTypes.GET_SYSTEM_DATA_SUCCEED: {
                state.systemData = payload;
                return state;
            }
            case systemActionTypes.CREATE_SYSTEM_SUCCEED: {
                state.systemList.push(payload);
                state.totalSize++;
                return state;
            }
            case systemActionTypes.UPDATE_SYSTEM_SUCCEED: {
                const updatedId = state.systemList.findIndex((item)=>item._id===payload._id);
                state.systemList[updatedId]=payload;
                return state;
            }
            case systemActionTypes.DELETE_SYSTEM_SUCCEED: {
                const deletedId = state.systemList.findIndex((item)=>item._id===payload._id);
                state.systemList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }

            case authActionTypes.USER_LOGOUT_SUCCEED: {
                state.availableList = [];
                state.availableLoaded = false;
                state.systemList = [];
                state.systemData = null;
                state.totalSize = 0;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default systemData;
