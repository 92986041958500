import React, {useState} from 'react';
import {Button, Card, CardBody, CardTitle} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import { config } from '../../../config';

let en = require("apexcharts/dist/locales/en.json")
let it = require("apexcharts/dist/locales/it.json")

const AreaDataGraph = (props) => {

    const {t} = useTranslation();

    const optionsCommon = {
        chart: {
            height: 350,
            type: "area",
            toolbar: {
                show: false,
            },
            locales: [en, it],
            defaultLocale: 'en',
        },
        stroke: {curve: "smooth", width: 2},
        colors: [config.getColors().blue, config.getColors().green],
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100],
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
        }
    }

    const [activeState, setActiveState] = useState({
        one: {
            activeD: true,
            activeM: false,
            active6M: false,
            activeY: false,
        },
        two: {
            activeD: true,
            activeM: false,
            active6M: false,
            activeY: false,
        }
    });

    const [optionsOne, setOptionsOne] = useState({
        xaxis: {
            type: "datetime",
            min: moment().startOf("day").add(1, "hour").valueOf(),
            max: moment().valueOf(),
            tickAmount: 6,
            labels: {
                format: 'MMM dd, HH:mm',
                datetimeUTC: false
            }
        },
        tooltip: {
            x: {format: 'MMM dd, HH:mm'}
        }
    })

    const [optionsTwo, setOptionsTwo] = useState({
        xaxis: {
            type: "datetime",
            min: moment().startOf("day").add(1, "hour").valueOf(),
            max: moment().valueOf(),
            tickAmount: 6,
            labels: {
                format: 'MMM dd, HH:mm',
                datetimeUTC: false
            }
        },
        tooltip: {
            x: {format: 'MMM dd, HH:mm'}
        }
    })

    const updateChartDay = (id) => {
        props.getGraphData(id, 'today')

        if (id === 0) {
            setOptionsOne({
                xaxis: {
                    ...optionsOne.xaxis,
                    min: moment().startOf("day").add(1, "hour").valueOf(),
                    labels: {
                        format: 'MMM dd, HH:mm',
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {format: 'MMM dd, HH:mm'}
                }
            })
            setActiveState({
                ...activeState,
                one: {
                    activeD: true,
                    activeM: false,
                    active6M: false,
                    activeY: false,
                }
            })
        } else {
            setOptionsTwo({
                xaxis: {
                    ...optionsTwo.xaxis,
                    min: moment().startOf("day").add(1, "hour").valueOf(),
                    labels: {
                        format: 'MMM dd, HH:mm',
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {format: 'MMM dd, HH:mm'}
                }
            })
            setActiveState({
                ...activeState,
                two: {
                    activeD: true,
                    activeM: false,
                    active6M: false,
                    activeY: false,
                }
            })
        }
    }

    const updateChartMonthly = (id) => {
        props.getGraphData(id, 'lastmonth')

        if (id === 0) {
            setOptionsOne({
                xaxis: {
                    ...optionsOne.xaxis,
                    min: moment().subtract(1, 'months').valueOf(),
                    labels: {
                        format: 'MMM dd, HH:mm',
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {format: 'MMM dd, HH:mm'}
                }
            })
            setActiveState({
                ...activeState,
                one: {
                    active6M: false,
                    activeM: true,
                    activeY: false,
                }
            })
        } else {
            setOptionsTwo({
                xaxis: {
                    ...optionsTwo.xaxis,
                    min: moment().subtract(1, 'months').valueOf(),
                    labels: {
                        format: 'MMM dd, HH:mm',
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {format: 'MMM dd, HH:mm'}
                }
            })
            setActiveState({
                ...activeState,
                two: {
                    active6M: false,
                    activeM: true,
                    activeY: false,
                }
            })
        }
    }

    const updateChartSixMonth = (id) => {
        props.getGraphData(id, 'lastsixmonth')

        if (id === 0) {
            setOptionsOne({
                xaxis: {
                    ...optionsOne.xaxis,
                    min: moment().subtract(6, 'months').valueOf(),
                    labels: {
                        format: 'MMM dd, HH:mm',
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {format: 'MMM dd, HH:mm'}
                }
            })
            setActiveState({
                ...activeState,
                one: {
                    active6M: true,
                    activeM: false,
                    activeY: false,
                }
            })
        } else {
            setOptionsTwo({
                xaxis: {
                    ...optionsTwo.xaxis,
                    min: moment().subtract(6, 'months').valueOf(),
                    labels: {
                        format: 'MMM dd, HH:mm',
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {format: 'MMM dd, HH:mm'}
                }
            })
            setActiveState({
                ...activeState,
                two: {
                    active6M: true,
                    activeM: false,
                    activeY: false,
                }
            })
        }
    }

    const updateChartYearly = (id) => {
        props.getGraphData(id, 'lastyear')

        if (id === 0) {
            setOptionsOne({
                xaxis: {
                    ...optionsOne.xaxis,
                    min: moment().subtract(1, 'years').valueOf(),
                    labels: {
                        format: 'MMM dd',
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {format: 'MMM dd'}
                }
            })
            setActiveState({
                ...activeState,
                one: {
                    active6M: false,
                    activeM: false,
                    activeY: true,
                }
            })
        } else {
            setOptionsTwo({
                xaxis: {
                    ...optionsTwo.xaxis,
                    min: moment().subtract(1, 'years').valueOf(),
                    labels: {
                        format: 'MMM dd',
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {format: 'MMM dd'}
                }
            })
            setActiveState({
                ...activeState,
                two: {
                    active6M: false,
                    activeM: false,
                    activeY: true,
                }
            })
        }
    }

    return (
        <Row>
            {["humidity", "temperature"].map((el, id) => {
                return (
                    <Col md={6} className={'card-plt ' + (id === 0 ? 'card-first' : ' card-second')}>
                        <Card>
                            <CardBody>
                                <CardTitle className='d-flex justify-content-between'>
                                    <div>{props.name} - {t(`dashboard.areas.topic.${el}`)}</div>
                                    <div className="toolbar mb-2 button-items text-end">
                                        <Button
                                            color="light"
                                            size="sm"
                                            type="button"
                                            className={activeState[id === 0 ? 'one' : 'two'].activeD ? 'btn-selected' : ''}
                                            onClick={() => updateChartDay(id)}
                                            id="one_day"
                                            style={{marginLeft: 5}}
                                        >
                                            1D
                                        </Button>
                                        <Button
                                            color="light"
                                            size="sm"
                                            type="button"
                                            className={activeState[id === 0 ? 'one' : 'two'].activeM ? 'btn-selected' : ''}
                                            onClick={() => updateChartMonthly(id)}
                                            id="one_month"
                                            style={{marginLeft: 5}}
                                        >
                                            1M
                                        </Button>
                                        <Button
                                            color="light"
                                            size="sm"
                                            type="button"
                                            className={activeState[id === 0 ? 'one' : 'two'].active6M ? 'btn-selected' : ''}
                                            onClick={() => updateChartSixMonth(id)}
                                            id="six_months"
                                            style={{marginLeft: 5}}
                                        >
                                            6M
                                        </Button>
                                        <Button
                                            color="light"
                                            size="sm"
                                            type="button"
                                            className={activeState[id === 0 ? 'one' : 'two'].activeY ? 'btn-selected' : ''}
                                            onClick={() => updateChartYearly(id)}
                                            id="one_year"
                                            style={{marginLeft: 5}}
                                        >
                                            1Y
                                        </Button>
                                    </div>
                                </CardTitle>
                                <ReactApexChart
                                    options={id === 0 ?
                                        {
                                            ...optionsCommon,
                                            ...optionsOne
                                        } : {
                                            ...optionsCommon,
                                            ...optionsTwo
                                        }
                                    }
                                    series={
                                        [{
                                            name: t("dashboard.areas.topic.air"),
                                            data: id === 0 ? props.humChart.air : props.tempChart.air
                                        }, {
                                            name: t('dashboard.areas.topic.soil'),
                                            data: id === 0 ? props.humChart.soil : props.tempChart.soil
                                        }]
                                    }
                                    type="area"
                                    height={240}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                )
            })}
        </Row>
    )
}

export default AreaDataGraph;