import React, {useEffect, useState} from "react"
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap"
import {useTranslation} from "react-i18next";


const TemparatureDetails = React.memo(props => {

    const {t} = useTranslation();

    const RenderItems = () => {

        const GRAPH_OFFSET = 15;

        const Data = props.temparatureData.devices.map((tempData, id) => {

            return (
                <li key={id} className="list-group-item">
                    <div className="py-2">
                        <h5 style={{textAlign: 'left', fontWeight: 450}} className="font-size-14">
                            {tempData ? tempData.name : ''} <span
                            className="float-end">{(tempData && tempData.state) ? Number(tempData.state.value) : 0} &#176;</span>
                        </h5>
                        <div className="progress animated-progess progress-sm">
                            <div
                                className={id === 0 ? "progress-bar bg-blue" : id === 1 ? "progress-bar bg-yellow" : "progress-bar bg-green"}
                                role="progressbar"
                                style={{width: ((tempData && tempData.state) ? Number(tempData.state.value) + GRAPH_OFFSET : 0) + "%"}}
                                aria-valuenow={(tempData && tempData.state) ? Number(tempData.state.value) + GRAPH_OFFSET : 0}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            />
                        </div>
                    </div>
                </li>
            )
        })

        return (Data);
    }

    return (
        <React.Fragment>
            <Card className='temp-card'>
                <CardBody>
                    <CardTitle style={{textAlign: 'left', marginTop: 1}}>{t("dashboard.temperature.topic")}</CardTitle>
                    <Row>
                        {props.temparatureData ?
                            <Col className='me-3 mb-2'>
                                <Row><Col style={{marginRight: 12}} className='text-end'>Media</Col></Row>
                                <Row><Col style={{fontWeight: 500}}
                                          className='text-end font-size-16'>{props.temparatureData.avg} <span>&#176;</span></Col></Row>
                            </Col>
                            :
                            <Col className='font-size-16 text-end'>{t("dashboard.temperature.average")} &#176;</Col>
                        }
                    </Row>

                    <hr/>

                    <div className='pt-2 pb-2 temprate-list'>
                        <ul className="list-group list-group-flush">

                            {props.temparatureData && props.temparatureData.devices && <RenderItems/> }

                        </ul>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
})

export default TemparatureDetails;
