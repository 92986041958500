import React, {useEffect, useState} from 'react'
import MetaTags from 'react-meta-tags';
import {useDispatch, useSelector} from 'react-redux'
import {Alert} from "reactstrap";

import Routes from './routes/Routes';
import OverlaySpinner from "./components/common/OverlaySpinner";

import {getAvailableSystemsList} from "./redux/actions/systemActions";
import {config} from "./config";
import {getUnreadCount} from "./redux/actions/notificationActions";
import {getMyDetails} from "./redux/actions/authActions";
import AuthenticateHelper from "./helpers/authenticateHelper";
import OverlayButton from "./components/common/OverlayButton";
import {useLocation} from "react-router-dom";

function getFaviconEl() {
    return document.getElementById("favicon");
}

const App = () => {

    const TARGET_CODE = config.TARGET_CODE;
    const authorization = AuthenticateHelper();

    const dispatch = useDispatch();
    // const location = useLocation();

    const loadingUtils = useSelector((state) => state.loadingData)
    const role = useSelector((state) => state.authData?.userDetails?.role)
    const available = useSelector((el) => el.systemData.availableList)
    const availableLoaded = useSelector((el) => el.systemData.availableLoaded)

    const [alert, setAlert] = useState('')

    useEffect(() => {
        if (loadingUtils.error) {
            setAlert(loadingUtils.error.message)
            setTimeout(() => {
                setAlert('')
            }, 10000)
        } else if (!loadingUtils.error && alert) {
            setAlert('')
        }
    }, [JSON.stringify(loadingUtils.error)])

    useEffect(() => {
        const favicon = getFaviconEl();
        if (TARGET_CODE === 'PLT') {
            favicon.href = "/faviconPLT.png"
        } else {
            favicon.href = "/favicon.png"
        }
    }, [])

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(getAvailableSystemsList())
            dispatch(getUnreadCount())
        }
    }, [localStorage.getItem('token')])

    useEffect(() => {
        if (!role && authorization) {
            dispatch(getMyDetails())
        }
    }, [])

    const requestFull=()=>{
        window.getFullScreen();
    }

    return (
        <>
            <div className='layout-container'>
                <MetaTags>
                    <title>{TARGET_CODE === 'PLT' ? "PlantCare 5.0" : "Velmare"}</title>
                </MetaTags>

                <OverlayButton handle={requestFull}/>
                {alert && <Alert style={{position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 1000, textAlign: 'right'}} color='danger'>{alert}</Alert>}
                <OverlaySpinner isLoading={(authorization && !availableLoaded) || (loadingUtils.loading && ((role === "guest" || role === "customer") && availableLoaded && available?.length))}/>
                {((availableLoaded && role) || !authorization) && <Routes/>}
            </div>
        </>
    );
}

export default App;
