import React from 'react';
import {Row, Col, Card, CardBody, Button} from "reactstrap"

import profileImg from "./../../assets/images/profile-img.png"
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


const UserDetails = (props) => {

    const {t} = useTranslation()

    return (
        <React.Fragment>
            <Card className="overflow-hidden user-card">
                <div className="bg-primary bg-soft">
                    <Row>
                        <Col xs="8" style={{textAlign: 'left'}}>
                            <div className="text-primary ps-3 pt-3">
                                <h5 className="text-primary text-nowrap">{t('dashboard.user.welcome')} {' '}
                                    {props.userData ? props.userData?.firstname : ''}!</h5>
                            </div>
                        </Col>
                        <Col xs="4" className="align-self-end">
                            <img style={{maxHeight: '70px'}} src={profileImg} alt="" className="img-fluid"/>
                        </Col>
                    </Row>
                </div>
                <CardBody style={{paddingTop: 0, paddingBottom: 0}}>
                    <Row>
                        <Col className='col-3'>
                            <div className="avatar-md mb-0 mt-0">
                                <div
                                    className='rounded-circle bg-primary profile-img img-thumbnail d-flex justify-content-center align-items-center text-white font-size-24'
                                    style={{marginTop: '-15px', border: '3px solid #fff', maxHeight:'62px', maxWidth:'62px'}}
                                >
                                    {props.userData ? props.userData?.fullname?.split(' ')[0][0] : ''}{props.userData ? props.userData?.fullname?.split(' ')[1][0] : ''}
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginTop: -5}}
                             className='col-9 text-start font-size-15 d-flex align-items-center justify-content-between'>
                            <div style={{maxWidth: "calc(100% - 120px)"}}>
                                <div
                                    className='fw-medium text-nowrap overflow-hidden' style={{textOverflow: "ellipsis"}}>{props.userData ? props.userData?.fullname : ''}</div>
                                <div
                                    className='font-size-12 text-semi-dark fw-light'>{props.userData ? props.userData?.role : ''}</div>
                            </div>
                            {props.project !== 'VLM_LOCAL' &&
                                <div>
                                    <Link
                                        to={`/settings`}
                                        style={{textDecoration: 'none'}}>
                                        <Button color='primary' className='d-flex ps-3 pt-1 pb-1'>
                                            <div className='text-white'>{t('settings.title')}</div>
                                            <i className='ms-1 bx bx-right-arrow-alt bx-xs text-white'
                                               style={{marginTop: 1}}/>
                                        </Button>
                                    </Link>
                                </div>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default UserDetails;