import React, {useEffect, useState} from "react";
import {ModalHeader, Modal, ModalBody, Button} from "reactstrap";
import moment from "moment";
import ReactApexChart from "react-apexcharts"
import {useTranslation} from "react-i18next";

import TimeRangeSelect from "../others/TimeRangeSelect";
import { config } from "../../config";

let en = require("apexcharts/dist/locales/en.json")
let it = require("apexcharts/dist/locales/it.json")

const GraphModal = (props) => {

    const { t } = useTranslation();

    const toggleModal = () => {
        props.setIsOpen(!props.isOpen)
    }

    const [filterData, setFilterData] = useState({
        value: 'lastyear',
        from: null,
        to: null
    })
    const defaultGraphOptions = {
        chart: {
            height: 350,
            //type: "area",
            toolbar: {
                show: false,
            },
            locales: [en, it],
            defaultLocale: 'en',
        },
        stroke: { curve: "smooth", width: 2 },
        xaxis: {
            type: "datetime",
            min:  moment().subtract(1, 'years').valueOf(),
            max: moment().valueOf(),
            tickAmount: 6,
            labels: {
                format: 'dd MMM',
                datetimeUTC: false
            }
        },
        colors: [config.getColors().primary],
        dataLabels: {
            enabled: false,

        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100],
            },
        },
        markers: {
            size: 3,
            strokeWidth: 3,

            hover: {
                size: 4,
                sizeOffset: 2,
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
        },
        tooltip:{
            x:{ format: 'dd MMM' }
        }
    };

    const [options, setOptions] = useState(defaultGraphOptions);

    const onApplyFilters=(value, dates)=>{
        let min;
        let max;
        let format;

        if(config.TARGET_PROJECT == "VLM") {
            if(value === 'today'){
                min = moment().subtract(1, 'days').valueOf();
                format = 'MMM dd, HH:mm'
            } else if ( value === 'lastweek'){
                min = moment().subtract(1, 'weeks').valueOf();
                format = 'MMM dd, HH:mm'
            } else if( value === 'lastmonth'){
                min = moment().subtract(1, 'months').valueOf();
                format = 'MMM dd'
            } else if ( value === 'lastsixmonth'){
                min = moment().subtract(6, 'months').valueOf();
                format = 'MMM dd'
            } else if ( value === 'lastyear'){
                min = moment().subtract(1, 'years').valueOf();
                format = 'yyyy MMM'
            } else {
                min = moment(dates.fromDate).valueOf();
                max = moment(dates.toDate).valueOf();
                format = 'yyyy MMM dd, HH:mm'
            }

        } else {
            if(value === 'today'){
                min = moment().subtract(1, 'days').valueOf();
                format = 'MMM dd, HH:mm'
            } else if ( value === 'lastweek'){
                min = moment().subtract(1, 'weeks').valueOf();
                format = 'MMM dd, HH:mm'
            } else if( value === 'lastmonth'){
                min = moment().subtract(1, 'months').valueOf();
                format = 'MMM dd, HH:mm'
            } else if ( value === 'lastsixmonth'){
                min = moment().subtract(6, 'months').valueOf();
                format = 'MMM dd, HH:mm'
            } else if ( value === 'lastyear'){
                min = moment().subtract(1, 'years').valueOf();
                format = 'MMM dd'
            } else {
                min = moment(dates.fromDate).valueOf();
                max = moment(dates.toDate).valueOf();
                format = 'yyyy MMM dd, HH:mm'
            }
        }

        props.getGraphData(value, dates.fromDate, dates.toDate)

        setFilterData({
            value: value,
            from: dates.fromDate,
            to: dates.toDate
        })
        setOptions({
            ...options,
            xaxis:{
                ...options.xaxis,
                min: min,
                max: max ? max : moment().valueOf(),
                labels:{
                    format: format,
                    datetimeUTC: false
                }
            },
            tooltip:{
                x: { format: format }
            }
        })
    }

    const onExportData=()=>{
        props.onExport(filterData.value, filterData.from, filterData.to);
    }

    return (
        <Modal
            isOpen={props.isOpen}
            autoFocus={true}
            tabIndex="-1"
            data-toggle="modal"
            size="xl"
        >
            <div className="modal-content">
                <ModalHeader toggle={toggleModal}>{props.title}</ModalHeader>

                <ModalBody>
                    {props.data}

                    <div className='mt-5 mb-5'>
                        <TimeRangeSelect onApplyFilters={onApplyFilters}/>
                    </div>

                    <div className='font-size-14 mb-2 fw-medium'>{t("history.graph.title")}</div>
                    <ReactApexChart
                        options={{...options, ...props.graphOptions}}
                        series={[props.graphData]}
                        type={props.graphType || "area"}
                        height={240}
                    />

                    {!props.hideExport &&
                        <div className='mt-2 mb-2'>
                            <Button onClick={onExportData} color='primary'>{t("main.exportRaw")}</Button>
                        </div>
                    }

                </ModalBody>
            </div>
        </Modal>
    )
}

export default GraphModal