import React, {useState, useEffect} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams, Link, useLocation} from "react-router-dom";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";

import SearchBar from "../../components/others/SearchBar";
import TableFirst from "../../components/tables/TableFirst";
import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import DeleteModal from "../../components/modals/DeleteModal";

import { ReactComponent as Book } from '../../assets/icons/book-open.svg'

import {
    deleteRegisterEntry,
    getRegisterEntriesCategoriesList,
    getRegisterEntriesList
} from "../../redux/actions/orchardRegisterActions";
import {config} from "../../config";

const RegisterEntryList = () => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const [deleteId, setDeleteId] = useState(null)
    const [entrySearch, setEntrySearch] = useState('')

    const dispatch = useDispatch();

    const available = useSelector((el) => el.systemData.availableList)
    const orchardRegisterData = useSelector((el)=> el.orchardRegisterData)
    const registerEntryCategories = useSelector((el)=> el.orchardRegisterData.registerEntryCategoriesList)

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            history.push(`/systems`)
        } else {
            dispatch(getRegisterEntriesList(params.systemId, params.categoryId, 1, null))
            dispatch(getRegisterEntriesCategoriesList(params.systemId))
        }
    }, [location.pathname])

    useEffect(() => {
        if(registerEntryCategories.length) {
            if(!registerEntryCategories.some((el) => el._id === params.categoryId)) {
                history.push(`/systems`)
            }
        }
    }, [JSON.stringify(registerEntryCategories)])

    const getDataList=(page)=>{
        dispatch(getRegisterEntriesList(params.systemId, params.categoryId, page, entrySearch ? entrySearch : null))
    }

    const onDeleteRegister=()=>{
        let id = deleteId;
        setDeleteId(null)
        dispatch(deleteRegisterEntry(id, params.systemId))
    }

    const onRegisterSearch=()=>{
        dispatch(getRegisterEntriesList(params.systemId, params.categoryId, 1, entrySearch))
    }



    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <Book className='pe-4 tap-icon'/>
                        </div>
                        <div className="row-details">
                            <div className='text-new-dark fw-bold font-size-13'>{row.title}</div>
                            <div>
                                <div className='fw-light text-semi-dark font-size-12'>{t("main.date")}: {moment(row.date).format('YYYY-MM-DD, HH:mm')}</div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            key: "edit-delete-save",
            text: "",
            dataField: "",
            style: {textAlign: "right"},
            headerStyle: {backgroundColor: "white", boxShadow: "0"},
            formatter: (rowContent, row) => {
                return (
                    <div className="text">
                        <Link to={`/system/${params.systemId}/register-entries/category/${params.categoryId}/entry/view/${row._id}`}><Button color="primary">{t("main.view")}</Button></Link>
                        <Link to={`/system/${params.systemId}/register-entries/category/${params.categoryId}/entry/edit/${row._id}`}><Button color="warning" className="ms-2">{t("main.edit")}</Button></Link>
                        <Button onClick={()=> setDeleteId(row._id)} color='danger' className='ms-2'>{t("main.delete")}</Button>
                    </div>
                );
            },
        },
    ];

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={registerEntryCategories.find((el) => el._id === params.categoryId)?.label[config.LANGUAGE] ?? registerEntryCategories.find((el) => el._id === params.categoryId)?.label?.it}
                    systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/register-entries`, name: t('registerEntry.breadcrumb')},
                        {path: `system/${params.systemId}/register-entries/category/${params.categoryId}/`, name: registerEntryCategories.find((el) => el._id === params.categoryId)?.label[config.LANGUAGE] ?? registerEntryCategories.find((el) => el._id === params.categoryId)?.label?.it}
                    ]}
                />
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between">
                            <div>
                                <div className='font-size-15 fw-medium'>{registerEntryCategories.find((el) => el._id === params.categoryId)?.label[config.LANGUAGE] ?? registerEntryCategories.find((el) => el._id === params.categoryId)?.label?.it}</div>
                                <div className='text-semi-dark mt-2 mb-4'>{t("registerEntry.category.pageSubTopic")}</div>
                            </div>
                            <Link to={`/system/${params.systemId}/register-entries`}>
                                <Button type="button" className="btn-secondary me-3">{t("main.back")}</Button>
                            </Link>
                        </div>
                        <div>
                            <SearchBar
                                name="orchard-register"
                                btnName={t("registerEntry.category.add")}
                                link={`system/${params.systemId}/register-entries/category/${params.categoryId}/entry`}
                                searchValue={entrySearch}
                                setSearchValue={setEntrySearch}
                                onSearch={onRegisterSearch}
                            />
                        </div>
                        <TableFirst
                            dataList={orchardRegisterData.registerEntriesList}
                            columns={tableColumns}
                            total={orchardRegisterData.totalSize}
                            getDataList={getDataList}
                            containerClass={'header-less-table-container'}
                            rowBorder={true}
                        />
                    </CardBody>
                </Card>
            </div>

            <DeleteModal
                onDelete={onDeleteRegister}
                modalShow={!!deleteId}
                setModalShow={setDeleteId}
            />
        </Layout>
    );
}

export default RegisterEntryList;
