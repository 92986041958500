import React from "react";
import { Button, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { roleData } from "../../utils/appData";

const UserForm = (props) => {
  const { t } = useTranslation();

  const onChangeHandler = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    let newFormState = { ...props.formState, [name]: fieldValue };

    if (name === "email" && props.isEdit) {
      const isEmailChanged = value !== props.initialEmail;

      newFormState = {
        ...newFormState,
        email_verified: !isEmailChanged,
        send_verification_email: isEmailChanged,
      };
    }

    if (name !== "email" && name !== "role" && type !== "checkbox") {
      if (fieldValue.length > 50) {
        return;
      }
    }

    props.setFormState(newFormState);
  };

  return (
    <form onSubmit={props.onSaveClick}>
      <Row>
        <div className="mt-3 col-12">
          <div>
            <label className="mb-2 p-0" htmlFor="email">
              {t("users.form.field1.name")} *
            </label>
          </div>
          <div>
            <Input
              type="email"
              name="email"
              id="email"
              className="form-control"
              value={props.formState.email}
              onChange={onChangeHandler}
              required
              placeholder={t("users.form.field1.placeholder")}
            />
          </div>
        </div>

        <div className="mt-3 col-12">
          <div>
            <label className="mb-2 p-0" htmlFor="firstname">
              {t("users.form.field2.name")} *
            </label>
          </div>
          <div className="form-limitation-container">
            <Input
              type="text"
              name="firstname"
              id="firstname"
              className="form-control"
              value={props.formState.firstname}
              onChange={onChangeHandler}
              required
              placeholder={t("users.form.field2.placeholder")}
            />
            <div>{props.formState.firstname.length}/50</div>
          </div>
        </div>

        <div className="mt-3 col-12">
          <div>
            <label className="mb-2 p-0" htmlFor="lastname">
              {t("users.form.field3.name")} *
            </label>
          </div>
          <div className="form-limitation-container">
            <Input
              type="text"
              name="lastname"
              id="lastname"
              className="form-control"
              value={props.formState.lastname}
              onChange={onChangeHandler}
              required
              placeholder={t("users.form.field3.placeholder")}
            />
            <div>{props.formState.lastname.length}/50</div>
          </div>
        </div>

        <div className="mt-3 col-12">
          <div>
            <label className="mb-2 p-0" htmlFor="password">
              {t("users.form.field4.name")} *
            </label>
          </div>
          <div className="form-limitation-container">
            <Input
              type="password"
              name="password"
              id="password"
              className="form-control"
              value={props.formState.password}
              onChange={onChangeHandler}
              required={!props.isEdit}
              placeholder={t("users.form.field4.placeholder")}
            />
            <div>{props.formState.password.length}/50</div>
          </div>
        </div>

        <div className="mt-3 col-12">
          <div>
            <label className="mb-2 p-0" htmlFor="confirmpassword">
              {t("users.form.field5.name")} *
            </label>
          </div>
          <div className="form-limitation-container">
            <Input
              type="password"
              name="confirmpassword"
              id="confirmpassword"
              className="form-control"
              value={props.formState.confirmpassword}
              onChange={onChangeHandler}
              required={!(props.isEdit && !props.formState.password)}
              placeholder={t("users.form.field5.placeholder")}
            />
            <div>{props.formState.confirmpassword.length}/50</div>
          </div>
        </div>

        <div className="mt-3 col-12">
          <div>
            <label className="mb-2 p-0" htmlFor="role">
              {t("users.form.field6.name")} *
            </label>
          </div>
          <div>
            <Input
              type="select"
              name="role"
              id="role"
              className="form-control form-select"
              value={props.formState.role}
              onChange={onChangeHandler}
              required
            >
              <option hidden value="">
                {t("users.form.field6.placeholder")}
              </option>
              {roleData.map((el, id) => {
                return (
                  <option key={id} value={el.value}>
                    {t(el.label)}
                  </option>
                );
              })}
            </Input>
          </div>
        </div>

        {props.formState?.role === "supervisor" ? (
          <div className="mt-3 col-12">
            <div>
              <label
                className="mb-2 p-0"
                htmlFor="model_modification_allowance"
              >
                {t("users.form.field7.name")} *
              </label>
            </div>
            <div>
              <Input
                type="select"
                name="model_modification_allowance"
                id="model_modification_allowance"
                className="form-control form-select"
                value={props.formState.model_modification_allowance}
                onChange={onChangeHandler}
                required
              >
                <option value="yes">{t("main.yes")}</option>
                <option value="no">{t("main.no")}</option>
              </Input>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="mt-3 row">
          <div className="col-6 d-flex align-items-center">
            <div className="form-check">
              <Input
                type="checkbox"
                name="email_verified"
                id="email_verified"
                className="form-check-input"
                checked={props.formState.email_verified || false}
                onChange={onChangeHandler}
              />
              <label className="form-check-label ms-2" htmlFor="email_verified">
                {t("users.form.field8.name")}
              </label>
            </div>
          </div>

          <div className="col-6 d-flex align-items-center">
            <div className="form-check">
              <Input
                type="checkbox"
                name="send_verification_email"
                id="send_verification_email"
                className="form-check-input"
                checked={props.formState.send_verification_email || false}
                onChange={onChangeHandler}
              />
              <label
                className="form-check-label ms-2"
                htmlFor="send_verification_email"
              >
                {t("users.form.field9.name")}
              </label>
            </div>
          </div>
        </div>
      </Row>

      <div className="col-12 text-end mt-3 form-btn-grp">
        <Button color="primary" type="submit" className="me-2">
          {t("main.save")}
        </Button>
        <Link to={`/users`}>
          <Button type="button" className="btn-secondary">
            {t("main.cancel")}
          </Button>
        </Link>
      </div>
    </form>
  );
};

export default UserForm;
