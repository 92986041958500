import { config } from "../config";

function genericInfection(tFn) {
    return {
        yaxis: {
            min: 0,
            max: 100,
        },
        annotations: {
            yaxis: [
                // Low risk
                {
                    y: 1,
                    y2: 50,
                    borderColor: "#000",
                    fillColor: config.getColors().green,
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        offsetX: -10,
                        borderColor: "#333",
                        style: {
                            fontSize: "10px",
                            color: "#111",
                            background: config.getColors().green,
                        },
                        text: tFn("generic-infection.low"),
                    },
                },
                // Moderate risk
                {
                    y: 51,
                    y2: 70,
                    borderColor: "#000",
                    fillColor: config.getColors().yellow,
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        offsetX: -10,
                        borderColor: "#333",
                        style: {
                            fontSize: "10px",
                            color: "#111",
                            background: config.getColors().yellow,
                        },
                        text: tFn("generic-infection.moderate"),
                    },
                },
                // High risk
                {
                    y: 71,
                    y2: 100,
                    borderColor: "#000",
                    fillColor: config.getColors().red,
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        offsetX: -10,
                        borderColor: "#333",
                        style: {
                            fontSize: "10px",
                            color: "#111",
                            background: config.getColors().red,
                        },
                        text: tFn("generic-infection.high"),
                    },
                },
            ],
        },
    };
}

function degreeDays(attributes, tFn) {
    const firstGenerationThreshold = attributes?.firstGenThreshold;
    if (isNaN(firstGenerationThreshold)) {
        return {};
    }
    return {
        yaxis: {
            min: 0,
        },
        annotations: {
            yaxis: [
                // First generation threshold
                {
                    y: 0,
                    y2: firstGenerationThreshold,
                    fillColor: "#FFFFFF",
                    opacity: 0.2,
                    label: {
                        offsetY: 5,
                        offsetX: -10,
                        borderColor: "#333",
                        style: {
                            fontSize: "10px",
                            color: "#111",
                            background: config.getColors().red,
                        },
                        text: tFn("degreedays-sum.first-gen-threshold"),
                    },
                },
                // Above first generation threshold
                {
                    y: firstGenerationThreshold,
                    y2: 10000,
                    borderColor: "#000",
                    fillColor: config.getColors().red,
                    opacity: 0.2,
                },
            ],
        },
    };
}

function getGraphOptions(type, attributes, tFn) {
    switch (type) {
        case "generic-infection":
            return genericInfection(tFn);
        case "degreedays-sum":
            return degreeDays(attributes, tFn);
        default:
            return {};
    }
}

export default getGraphOptions;
